import { Injectable } from '@angular/core';
import { ConfigurationLoader } from 'shared/configuration-loader';
import { ISearchData, PassThroughTypeUrlParams } from '../../../../models/customization';
import { CustomDomainLoader } from '../../../../services/custom-domain-loader';

@Injectable({
  providedIn: 'root'
})
export class CustomPassThroughService {
  private readonly customerId: string | null = '';

  constructor(
    private readonly customDomainLoader: CustomDomainLoader,
    private configLoader: ConfigurationLoader,
  ) {
    this.customerId = this.configLoader.configuration?.customerId;
  }

  public getRedirectSearch(keyword: string, url: string) {
    // convert url to lowercase and ##KEYWORD##
    return url.toLowerCase().replace(PassThroughTypeUrlParams.KEYWORD.toLowerCase(), keyword);
  }

  public getRedirectWorks(data: ISearchData, url: string) {
    if (data.author && data.title) {
      const title = encodeURIComponent(data.title);
      const author = encodeURIComponent(data.author);

      // convert url to lowercase and ##TITLE## && ##AUTHOR##
      return url.toLowerCase()
      .replace(PassThroughTypeUrlParams.TITLE.toLowerCase(), title)
      .replace(PassThroughTypeUrlParams.AUTHOR.toLowerCase(), author);
    }

    if (data?.title) {
      const title = encodeURIComponent(data.title);

      //Remove AUTHOR ##AUTHOR## not in use, convert url to lowercase && get extra parameters after author tag
      const prepareURL = url.toLowerCase();
      return prepareURL.replace(PassThroughTypeUrlParams.TITLE.toLowerCase(), title)
      .replace(PassThroughTypeUrlParams.AUTHOR.toLowerCase(), '');
    }

    if (data?.author) {
      const author = encodeURIComponent(data.author);

      //Remove Title ##TITLE## not in use, convert url to lowercase
      const prepareURL = url.toLowerCase()
      .replace(PassThroughTypeUrlParams.TITLE.toLowerCase(), '');

      //AUTHOR to lowercase since url is in lowercase
      return prepareURL.replace(PassThroughTypeUrlParams.AUTHOR.toLowerCase(), author);
    }
  }
}
