import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faBooks } from '@fortawesome/pro-light-svg-icons';
import { select, Store, } from '@ngrx/store';
import { EditionData } from 'app/citation/models/editions';
import { Cover } from 'app/models/cover';
import { I18NService } from 'app/services/i18-n.service';
import { ShareItResource, ShareItShareEntityType } from 'app/share-it/models/share-it';
import { QueryHelper } from 'core/utils/query-helper';
import { merge } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map } from 'rxjs/operators';
import { ResetFacetBubblesAction, UpdateSearchObjectAction } from 'search/actions/facet.actions';
import { SearchResultsReset } from 'search/actions/search.actions';
import { FacetURLFilters } from 'search/facets/models/facet-state';
import { SearchType } from 'search/models/search-type';
import { getLockedFacetsAsQueryParams, getSearchResultsMetadataForScroll } from 'search/reducers/root.reducer';
import { BulkFeatureKey } from '../../../../bulk-select/enums/bulk-feature-key';
import { Breakpoints } from '../../../../models/viewport';
import { DictionariesService } from '../../../../services/dictionaries.service';
import { ViewportService } from '../../../../services/viewport.service';
import { resetFocusedResourceId, resetScrollAndSelectedResourceId, setSelectedResourceId } from '../../../actions/entity.actions';
import { Edition, EntityTypes, FormatGroup, GetItRequest, HoldRequest } from '../../../models/entity';
import { getFocusedResourceId, getSelectedResourceId, getSelectedRollupTabIndex } from '../../../reducers/entity.reducer';
import { DiscoverV2BffService } from 'app/elements-v2/service/discover-v2-bff.service';

@Component({
  selector: 'app-rollup-card',
  templateUrl: './rollup-card.component.html',
  styleUrls: ['./rollup-card.component.scss'],
})
export class RollupCardComponent implements OnInit, OnDestroy {
  @Input() public formatGroup: FormatGroup;
  @Input() public bulkFeatureKey: BulkFeatureKey;
  @Input() public isListView?: boolean;
  @Output() public getIt$ = new EventEmitter<{ item: GetItRequest }>(true);
  @Output() public placeHold$ = new EventEmitter<{ item: HoldRequest }>(true);
  @ViewChild('searchCard', { static: true }) public searchCard: ElementRef;

  public queryParams = {};
  public subscription = new Subscription();
  public notFoundWords: string[] = [];
  public ariaTitleId: string;
  public entityTypes = EntityTypes;
  public resourceData: ShareItResource;
  public editionsData: EditionData[];
  public coverConfig: Cover;
  public language: string;
  public lockedFacetsQueryParams: FacetURLFilters;
  public originalSearchQueryValue: string;
  public originalSearchType: string;
  public readonly booksIcon = faBooks;
  public selectedTabName: string;
  public selectedTabRecordId: string;
  public author: string;
  public title: string;
  public materialType: string;
  public individualResultOnSrp: boolean
  public isWiderThanMd: boolean;

  public constructor(
    private store: Store,
    private i18nService: I18NService,
    private readonly router: Router,
    private readonly queryHelper: QueryHelper,
    private readonly route: ActivatedRoute,
    private readonly dictionaryService: DictionariesService,
    private discoverV2BffService: DiscoverV2BffService,
    private readonly viewportService: ViewportService,
  ) {}

  public ngOnInit(): void {
    this.language = this.i18nService.getLangFacetTitle(this.formatGroup.language);
    this.coverConfig = {
      coverUrl: this.formatGroup.coverUrl,
    };
    this.subscription.add(this.store.pipe(select(getSearchResultsMetadataForScroll)).subscribe(() => {
      this.setFocusOnSelectedCard();
    }));

    this.subscription.add(this.store.pipe(select(getSelectedRollupTabIndex(this.formatGroup.id))).subscribe((selectedTabIndex) => {
      if (this.formatGroup?.materialTabs?.length) {
        this.extractCitationsData(selectedTabIndex);
      }
    }));
    this.subscription.add(this.discoverV2BffService.$page.subscribe((page) => {
      if (page) {
        this.individualResultOnSrp = page.settings?.social_media_display?.individual_result_on_srp;
      }
    }));
    this.subscription.add(this.store.pipe(select(getLockedFacetsAsQueryParams)).subscribe((result) => {
      this.lockedFacetsQueryParams = result;
    }));

    this.subscription.add(this.route.queryParamMap.subscribe((params) => {
      this.originalSearchQueryValue = params.get('query');
      this.originalSearchType = params.get('searchType');
    }));

    this.notFoundWords = this.formatGroup && this.formatGroup.missingWords|| [];

    this.queryParams = {
      id: this.formatGroup.id,
      entityType: this.formatGroup.entityType,
    };
    this.ariaTitleId = `ariaTitleRollupCardId-${this.formatGroup.id}`;
    this.title = this.formatGroup?.title;
    this.author = this.formatGroup?.primaryAgent?.label;
    this.materialType = this.dictionaryService.getFormatDescriptionByCode(this.formatGroup?.materialTabs?.[0]?.materialTypes?.[0]);

    this.subscription.add(
      this.viewportService.isWiderThan(Breakpoints.RESOLUTION_MAX_MD).subscribe((isWiderThanMd) => {
        this.isWiderThanMd = isWiderThanMd;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSaveFocus(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.type === 'click') {
      this.store.dispatch(setSelectedResourceId({resourceId: this.formatGroup.id}));
    }
  }

  public onSeriesTypeSearch(event: KeyboardEvent, seriesTitle: string, seriesTitleSearch: string) {
    const searchText = `"${(seriesTitleSearch || seriesTitle).replace(/"/g, '')}"`;
    const searchType = SearchType.SERIES;
    if ((event.key === 'Enter' || event.type === 'click') &&
      (this.originalSearchQueryValue !== searchText || searchType !== this.originalSearchType)) {
      this.store.dispatch(resetScrollAndSelectedResourceId());
      this.store.dispatch(new SearchResultsReset());
      this.store.dispatch(new UpdateSearchObjectAction({searchParams: {type: {searchText: searchText }}, searchType}));
      this.store.dispatch(new ResetFacetBubblesAction({}));
      const queryParams = this.queryHelper.prepareQueryParams(searchText, this.lockedFacetsQueryParams, searchType);
      this.router.navigate(['/search'], queryParams);
    }
  }

  public extractCitationsData(selectedTabIndex: number) {
    this.editionsData = this.formatGroup.materialTabs?.[selectedTabIndex]?.editions?.map((edition: Edition) => ({
      instanceId: edition.id,
      editionStatement: [edition.edition],
      publicationDate: edition.publicationDate,
    } as EditionData));
    this.selectedTabRecordId = this.formatGroup.materialTabs?.[selectedTabIndex]?.editions?.[0]?.recordId;
    this.selectedTabName = this.formatGroup.materialTabs?.[selectedTabIndex]?.name;

    this.resourceData = {
      type: ShareItShareEntityType.RESOURCE,
      attributes: {
        id: this.formatGroup.id,
        url: `/search/card?recordId=${this.selectedTabRecordId}`,
        title: this.formatGroup.title,
        author: this.formatGroup.primaryAgent?.label || '',
        publicationDate: this.formatGroup.publicationDate || '',
        resourceType: this.formatGroup.entityType,
      }
    };
  }

  public setFocusOnSelectedCard() {
    this.subscription.add(
      merge(
        this.store.pipe(select(getSelectedResourceId)).pipe(map((resourceId) => [resourceId, false])),
        this.store.pipe(select(getFocusedResourceId)).pipe(map((resourceId) => [resourceId, true])),
      ).subscribe(([resourceId, unsetFocus]) => {
        if (resourceId === this.formatGroup.id) {
          setTimeout(() => {
            this.searchCard.nativeElement.focus();
            this.searchCard.nativeElement.scrollIntoView();

            if (unsetFocus) {
              this.store.dispatch(resetFocusedResourceId());
            }
          }, 0);
        }
      }));
  }
}
