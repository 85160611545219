import { Injectable } from '@angular/core';
import { WindowRefService } from './window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {
  private initialized = false;

  constructor(private windowRef: WindowRefService) { }

  initialize(): void {
    if (!this.initialized) {
      const local = ENVIRONMENT === 'local';
      const hostname = this.windowRef.host();
      const sdkSciprt = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      const oTautoScript = 'https://cdn.cookielaw.org/scripttemplates/OtAutoBlock.js';
      interface LibraryId { [key:string]: string | undefined };
      const scriptIds: LibraryId = {
        'iii-conv.com': '874e9a0f-db78-4555-aa29-3e65d7aca240',
        'iii-lab.eu': '0190e595-62a5-725e-b445-29755aeac229',
        'stellanj.org': '0190bb99-d488-7a13-ad4b-6460e2e4a1f7',
        'bccls.org': '0190bc1e-86cd-74e9-b326-2bc8adf41ba8',
        'aucklandlibraries.govt.nz': '0190d9d5-b6d3-7f3b-902a-cb3046e81552',
        'midhudsonlibraries.org': '0190df79-eaa1-7823-86d4-317d3d2d28c9',
        'phoenixpubliclibrary.org':'0190e09a-ed89-7d27-8ab0-4a70d8d2ce40',
        'rhpl.org': '0190e5d1-87cc-7140-89fe-34888afed942',
        'acpl.lib.in.us': '0190ebb0-86b5-7728-afd9-bf4477264a6d',
        'cmpl.org': '0190ebb5-823c-789e-830c-a31b1cd48858',
        'nlb.gov.sg': '0190ebb4-681f-740c-a5c2-eea07f23221e',
        'nypl.org': '0190ebc0-fabf-7cf5-9e38-62df069bdd13',
        'watsonvillelibrary.gov': '0190ebc2-b7bc-7a7c-a84e-128aeffb5c60',
        'monarchcatalog.org':'01916b62-16d4-7e7b-84f8-27a846434ce0',
        'default': '08f85ea6-474c-4120-8168-4a69704cb987'
      };
      let idScript = '874e9a0f-db78-4555-aa29-3e65d7aca240-test';
      if (!local) {
        const matchedHost = Object.keys(scriptIds).find(key => hostname.includes(key));
        idScript = scriptIds[matchedHost] || scriptIds['default'];
      }
      this.appendScript(sdkSciprt, idScript);
      this.appendScript(oTautoScript, idScript);
      this.appendInlineScript('function OptanonWrapper() { }');
      this.appendOverlayHandlingScript();
      this.initialized = true;
    }
  }

  private appendScript(src: string, id: string): void {
    const script = this.windowRef.nativeWindow().document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', id);
    this.windowRef.nativeWindow().document.head.insertBefore(script, this.windowRef.nativeWindow().document.head.firstChild);
  }

  private appendInlineScript(text: string): void {
    const optanonScript = this.windowRef.nativeWindow().document.createElement('script');
    optanonScript.type = 'text/javascript';
    optanonScript.text = text;
    this.windowRef.nativeWindow().document.head.insertBefore(optanonScript, this.windowRef.nativeWindow().document.head.firstChild);
  }

  private appendOverlayHandlingScript(): void {
    const scriptContent = `
      document.addEventListener("click", function(evt) {
        if (evt.target.id === 'close-pc-btn-handler') {
          document.querySelector('.onetrust-pc-dark-filter').style.display = 'none';
        }
      });
    `;
    this.appendInlineScript(scriptContent);
  }
}
