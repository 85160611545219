import { Component, OnInit } from '@angular/core';
import { FeatureToggleService } from '../../../services/feature-toggle.service';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-showcase-form-metadata-schedule-showcase',
  templateUrl: './custom-showcase-form-metadata-schedule-showcase.component.html',
  styleUrls: ['./custom-showcase-form-metadata-schedule-showcase.component.scss']
})
export class CustomShowcaseFormMetadataScheduleShowcaseComponent implements OnInit {
  public isShowCaseScheduleFlagEnabled = false;

  constructor(public readonly controlContainer: ControlContainer,
              private readonly featureToggleService: FeatureToggleService) {
  }

  public ngOnInit(): void {
    this.isShowCaseScheduleFlagEnabled = this.featureToggleService.getToggles()['DIS-31554_2024-08-30_schedule_showcase'];
  }

  get aboutShowcase(): UntypedFormGroup {
    return this.controlContainer.control as UntypedFormGroup;
  }
}
