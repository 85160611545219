import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TippyModule } from '@ngneat/helipopper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { BookshelfCardActionStateComponent } from 'common/components/bookshelf-card-action-state/bookshelf-card-action-state.component';
import { BookshelfCardComponent } from 'common/components/bookshelf-card/bookshelf-card.component';
import { BookshelfItemCardComponent } from 'common/components/bookshelf-item-card/bookshelf-item-card.component';
import { BookshelfItemsListComponent } from 'common/components/bookshelf-items-list/bookshelf-items-list.component';
import { EntityOptionsButtonComponent } from 'common/components/bookshelf-options-button/entity-options-button.component';
import { BookshelfTemplateComponent } from 'common/components/bookshelf-template/bookshelf-template.component';
import { ConfirmDeleteItemsComponent } from 'common/components/confirm-delete-items/confirm-delete-items.component';
import { ConfirmationModalContentComponent } from 'common/components/confirmation-modal-content/confirmation-modal-content.component';
import { CreateListComponent } from 'common/components/create-list/create-list.component';
import { CustomSectionComponent } from 'common/components/custom-section/custom-section.component';
import { ModalHeaderComponent } from 'common/components/modal-header/modal-header.component';
import { SearchBarComponent } from 'common/components/search-bar/search-bar.component';
import { SearchFacetsBubbleComponent } from 'common/components/search-facets-bubble/search-facets-bubble.component';
import { SelectListButtonComponent } from 'common/components/select-list-button/select-list-button.component';
import { SelectListComponent } from 'common/components/select-list/select-list.component';
import { ShowcaseCreatorComponent } from 'common/components/showcase-creator/showcase-creator.component';
import { AnnounceDirective } from 'common/directives/announce.directive';
import { BookshelfTemplateActionDirective } from 'common/directives/bookshelf-template-action.directive';
import { BookshelfTemplateBodyDirective } from 'common/directives/bookshelf-template-body.directive';
import { BookshelfTemplateSortActionDirective } from 'common/directives/bookshelf-template-sort-action.directive';
import { BookshelfTemplateSubheaderActionsDirective } from 'common/directives/bookshelf-template-subheader-actions.directive';
import { BookshelfTemplateSubheaderDirective } from 'common/directives/bookshelf-template-subheader.directive';
import { BookshelfTemplateTitleDirective } from 'common/directives/bookshelf-template-title.directive';
import { CardActionDirective } from 'common/directives/card-action.directive';
import { CardShareActionDirective } from 'common/directives/card-share-action.directive';
import { CardStatusDirective } from 'common/directives/card-status.directive';
import { FocusOnInitDirective } from 'common/directives/focus-on-init.directive';
import { LifecycleHooksDirective } from 'common/directives/lifecycle-hooks.directive';
import { YearDirective } from 'common/directives/year.directive';
import { StyleLoaderService } from 'common/services/style-loader.service';
import { ScriptLoaderService } from 'common/services/script-loader.service';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule, } from 'ngx-perfect-scrollbar';
import { OverdriveAndSierraEditionAvailabilityContainer } from 'search/containers/overdrive-and-sierra-edition-availability-container.component';
import { SuggestsService } from 'search/services/suggests.service';
import { ListEffects } from '../list/effects/list.effects';
import * as list from '../list/reducers/list.reducer';
import { BookshelfCardActionItemComponent } from './components/bookshelf-card-action-item/bookshelf-card-action-item.component';
import { BookshelfCardStatusComponent } from './components/bookshelf-card-status/bookshelf-card-status.component';
import {
  BookshelfRemoveSelectedFromListButtonComponent
} from './components/bookshelf-remove-from-list/bookshelf-remove-selected-from-list-button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { CustomDropdownOptionComponent } from './components/custom-dropdown/custom-dropdown-option/custom-dropdown-option.component';
import { CustomDropdownComponent } from './components/custom-dropdown/custom-dropdown.component';
import { CutTextWithCertainHeightComponent } from './components/cut-text-with-certain-height/cut-text-with-certain-height.component';
import { InformationModalComponent } from './components/information-modal/information-modal.component';
import { InnReachSearchComponent } from './components/inn-reach-search/inn-reach-search.component';
import { LoadMoreBtnComponent } from './components/load-more-btn/load-more-btn.component';
import { UniversalFooterComponent } from './components/universal-footer/universal-footer.component';
import { CoverDirective } from './directives/cover.directive';
import { ExternalLinkDirective } from './directives/external-link.directive';
import { RemoveHaspopupDirective } from './directives/remove-haspopup.directive';
import { RemoveTabindexOnYScrollDirective } from './directives/remove-tabindex-on-scroll.directive';
import { ConfiguredCurrencyPipe } from './pipes/configured-currency.pipe';
import { ConfiguredDatePipe } from './pipes/configured-date.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { SortByPipe } from './pipes/sort-by.pipe';
import { SortUsingCustomComparatorPipe } from './pipes/sort-using-custom-comparator.pipe';
import { RangePipe } from './pipes/range.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { BookshelfSearchBarComponent } from './components/bookshelf-search-bar/bookshelf-search-bar.component';
import { PasscodeVisibilityTogglerComponent } from './components/passcode-visibility-toggler/passcode-visibility-toggler.component';
import { DomChangeDirective } from './directives/dom-change.directive';
import { CoverComponent } from './components/cover/cover.component';
import { DefaultCoverComponent } from './components/default-cover/default-cover.component';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { FeatureToggleService } from 'app/services/feature-toggle.service';
import { OneTrustFooterButton } from './components/onetrust-footer-button/onetrust-footer-button.component';
import { CustomPassThroughComponent } from './components/custom-pass-through/custom-pass-through.component';
import { CustomPassThroughEffects } from 'common/components/custom-pass-through/effects/custom-passthrough.effects';
import { CustomPassThroughItemComponent } from './components/custom-pass-through/custom-pass-through-item/custom-pass-through-item.component';
import { ShareIconsButtonComponent } from 'common/components/share-icons/share-icons-button.component';


const COMPONENTS = [
  ConfirmationModalContentComponent,
  CutTextWithCertainHeightComponent,
  LoadMoreBtnComponent,
  CustomDropdownComponent,
  CustomDropdownOptionComponent,
  CustomDropdownOptionComponent,
  SearchBarComponent,
  UniversalFooterComponent,
  InnReachSearchComponent,
  CheckboxComponent,
  InformationModalComponent,
  BookshelfTemplateComponent,
  BookshelfCardComponent,
  BookshelfCardActionItemComponent,
  BookshelfCardActionStateComponent,
  BookshelfCardStatusComponent,
  BookshelfItemsListComponent,
  BookshelfItemCardComponent,
  EntityOptionsButtonComponent,
  BookshelfRemoveSelectedFromListButtonComponent,
  ConfirmDeleteItemsComponent,
  CustomCheckboxComponent,
  CreateListComponent,
  SelectListComponent,
  SelectListButtonComponent,
  ShowcaseCreatorComponent,
  SearchFacetsBubbleComponent,
  BookshelfSearchBarComponent,
  PasscodeVisibilityTogglerComponent,
  CustomSectionComponent,
  CoverComponent,
  DefaultCoverComponent,
  OneTrustFooterButton,
  CustomPassThroughItemComponent,
  ModalHeaderComponent,
  ShareIconsButtonComponent
];

const CONTAINERS = [
  OverdriveAndSierraEditionAvailabilityContainer,
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export const MATERIAL = [
  OverlayModule,
];

const PIPES = [
  JoinPipe,
  SortByPipe,
  SortUsingCustomComparatorPipe,
  ConfiguredCurrencyPipe,
  ConfiguredDatePipe,
  PluckPipe,
  RangePipe,
  SafePipe,
];

const DIRECTIVES = [
  CoverDirective,
  YearDirective,
  AnnounceDirective,
  FocusOnInitDirective,
  RemoveTabindexOnYScrollDirective,
  RemoveHaspopupDirective,
  LifecycleHooksDirective,
  ExternalLinkDirective,
  CardActionDirective,
  CardShareActionDirective,
  CardStatusDirective,
  BookshelfTemplateBodyDirective,
  BookshelfTemplateTitleDirective,
  BookshelfTemplateActionDirective,
  BookshelfTemplateSortActionDirective,
  BookshelfTemplateSubheaderDirective,
  BookshelfTemplateSubheaderActionsDirective,
  DomChangeDirective,
  CustomPassThroughComponent
];

@NgModule({
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    ...CONTAINERS,
    ...DIRECTIVES,
    ExternalLinkDirective,
    RangePipe,
    DomChangeDirective,
  ],
  exports: [
    ...PIPES,
    ...COMPONENTS,
    ...CONTAINERS,
    ...DIRECTIVES,
    A11yModule,
    TranslateModule,
  ],
  imports: [
    CommonModule,
    A11yModule,
    ...MATERIAL,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    RouterModule,
    TranslateModule.forChild(),
    NgbModule,
    FormsModule,
    NgClickOutsideDirective,
    FontAwesomeModule,
    TippyModule,
    StoreModule.forFeature(list.listFeatureKey, list.reducer),
    EffectsModule.forFeature([
      ListEffects,
      CustomPassThroughEffects
    ]),
    FeatureToggleModule,
  ],
  providers: [
    ...PIPES,
    CurrencyPipe,
    SuggestsService,
    ScriptLoaderService,
    StyleLoaderService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    FeatureToggleService,
  ],
})
export class SharedModule {
}
